import {
  defineStore
} from "pinia";

export const useStore = defineStore({
  id: "data",
  state: () => ({
    data: [],
    commits: [],
    selectedModule: null,
    selectedType: null,
    selectedTopic: null,
    selectedTarget: null,
    selectedTags: null,
    selectedDepartment: null,
    filterActive: false,
  }),
  actions: {
    async fetchData() {
      try {
        const response = await fetch("https://eld-mapper-backend.api.prod.sit.sys.odj.cloud/mod", {
          method: 'GET',
          credentials: 'include',
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        this.data = await response.json();
        sessionStorage.setItem('data', JSON.stringify(this.data));
      } catch (error) {
        console.error("There was a problem with the fetch operation: ", error);
      }
    },
    setSelectedModule(mod) {
      localStorage.setItem('mod', JSON.stringify(mod));
      this.selectedModule = mod;
    },
    setFilters(filters) {
      this.selectedType = filters.type;
      this.selectedTopic = filters.topic;
      this.selectedTarget = filters.target;
      this.selectedTags = filters.tags;
      this.selectedDepartment = filters.department;
      this.selectedDuration = filters.duration;
    },
    clearFilters() {
      this.selectedType = null;
      this.selectedTopic = null;
      this.selectedTarget = null;
      this.selectedTags = null;
      this.selectedDepartment = null;
      this.selectedDuration = null;
    },
    setFilterActive(value) {
      this.filterActive = value;
    }
  },
});