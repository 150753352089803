import {
  createApp
} from "vue";
import {
  createRouter,
  createWebHistory
} from "vue-router";
import App from "./App.vue";
import "./index.css";
import {
  createPinia
} from "pinia";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/lara-dark-amber/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import DashboardComponent from "./components/dashboard/DashboardComponent.vue";
import LoginComponent from "./components/login/LoginComponent.vue";
import ModuleFiler from "./components/filter/ModuleFilter.vue";
import MapModules from "./components/map/MapModules.vue";
import ModuleLibrary from "./components/library/ModuleLibrary.vue";
import ModuleComparator from "./components/comparator/ModuleComparator.vue";
import OrderTraining from "./components/order/OrderTraining.vue";
import CompetenceProfile from "./components/competence/CompetenceProfile.vue";
import DetailedModuleMap from "./components/map/detailed/DetailedModuleMap.vue";

const routes = [{
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    component: LoginComponent,
    meta: {
      routeName: "Login"
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardComponent,
    meta: {
      routeName: "Welcome to the Mapper!"
    },
  },
  {
    path: "/module-library",
    name: "module-library",
    component: ModuleLibrary,
    meta: {
      routeName: "Our Modules library!"
    },
  },
  {
    path: "/module-filter",
    component: ModuleFiler,
    meta: {
      routeName: "Filter Modules"
    },
  },
  {
    path: "/map-modules",
    component: MapModules,
    meta: {
      routeName: "Trainings Map"
    },
  },
  {
    path: "/module-comparator",
    name: "module-comparator",
    component: ModuleComparator,
    meta: {
      routeName: "Compare Modules"
    },
  },
  {
    path: "/order-training",
    component: OrderTraining,
    meta: {
      routeName: "Order new Training"
    },
  },
  {
    path: "/competence-profile",
    component: CompetenceProfile,
    meta: {
      routeName: "Competence Profile"
    },
  },
  {
    path: '/module/:short?',
    name: 'module',
    component: DetailedModuleMap,
    props: route => ({
      mod: route.params.mod
    }),
    meta: {
      routeName: "Training detailed view"
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);

app.use(createPinia());
app.use(PrimeVue);
app.use(router);
app.mount("#app");