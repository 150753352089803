<template>
  <div class="w-full h-full flex flex-col justify-center items-center px-4 custom:mt-2 xl:mt-0">
    <Button
      class="btn-filter custom:text-sm xl:text-base"
      @click="props.toggleFilter"
      :label="dictionary.sidebar_item3"
      icon="pi pi-angle-left"
      iconPos="right"
    />
    <Divider class="m-2" />
    <div class="w-full filter-btn-wrapper py-4">
      <Button
        class="sales sales-txt w-full mb-3 text-left custom:text-sm xl:text-base"
        :label="dictionary.filter_label7"
        @click="filterByDepartment(dictionary.filter_label7)"
      />
      <Button
        class="logistics logistics-txt w-full mb-3 text-left custom:text-sm xl:text-base"
        :label="dictionary.filter_label8"
        @click="filterByDepartment(dictionary.filter_label8)"
      />
      <Button
        class="hrs hrs-txt w-full mb-3 text-left custom:text-sm xl:text-base"
        :label="dictionary.filter_label9"
        @click="filterByDepartment(dictionary.filter_label9)"
      />
      <Button
        class="others others-txt w-full text-left custom:text-sm xl:text-base"
        :label="dictionary.filter_label10"
        @click="filterByDepartment(dictionary.filter_label10)"
      />
    </div>
    <Divider class="custom:m-0 xl:m-2" />
    <div class="w-full xl:py-4 custom:py-0">
      <FloatLabel class="w-full mb-3 custom:text-xs xl:text-base">
        <Dropdown
          v-model="selectedType"
          inputId="dd-type"
          :options="uniqueTypes"
          optionLabel="name"
          class="w-full custom:text-xs xl:text-base"
        />
        <label for="dd-type">{{ dictionary.filter_label1 }}</label>
      </FloatLabel>
      <FloatLabel class="w-full mb-3 custom:text-xs xl:text-base">
        <Dropdown
          v-model="selectedTopic"
          inputId="dd-topic"
          :options="uniqueTopics"
          optionLabel="name"
          class="w-full custom:text-xs xl:text-base"
          filter
        />
        <label for="dd-topic">{{ dictionary.filter_label3 }}</label>
      </FloatLabel>
      <FloatLabel class="w-full mb-3 custom:text-xs xl:text-base">
        <Dropdown
          v-model="selectedTarget"
          inputId="dd-target"
          :options="uniqueTarget"
          optionLabel="name"
          class="w-full custom:text-xs xl:text-base"
        />
        <label for="dd-target">{{ dictionary.filter_label5 }}</label>
      </FloatLabel>
      <FloatLabel class="w-full mb-3 custom:text-xs xl:text-base">
        <Dropdown
          v-model="selectedDuration"
          inputId="dd-target"
          :options="uniqueDuration"
          optionLabel="name"
          class="w-full custom:text-xs xl:text-base"
        />
        <label for="dd-target">{{ dictionary.filter_label11 }}</label>
      </FloatLabel>
      <FloatLabel class="w-full mb-3 custom:text-xs xl:text-base">
        <Dropdown
          v-model="selectedTags"
          inputId="dd-tags"
          :options="uniqueTags"
          optionLabel="name"
          class="w-full custom:text-xs xl:text-base"
          :disabled="tagsDisabled"
        />
        <label for="dd-tags">{{ dictionary.filter_label6 }}</label>
      </FloatLabel>
    </div>
    <Divider class="m-2" />
    <div class="w-full py-4">
      <Button
        :label="dictionary.filter_btn_clear"
        icon="pi pi-refresh"
        class="dataview-btn custom:p-1 custom:text-xs custom:h-8 custom:mt-1 xl:p-2 xl:text-base xl:h-auto xl:mt-0 rounded w-full"
        @click="clearFilters()"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, computed, watch } from "vue";
import { useStore } from "../../../store/store.js";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Divider from "primevue/divider";
import FloatLabel from "primevue/floatlabel";
import { dictionary } from "../../dictionary.js";
const store = useStore();

const props = defineProps({
  toggleFilter: Function,
  data: Array,
});

const selectedType = computed({
  get: () => store.selectedType,
  set: (value) => (store.selectedType = value),
});
const selectedTopic = computed({
  get: () => store.selectedTopic,
  set: (value) => (store.selectedTopic = value),
});
const selectedTarget = computed({
  get: () => store.selectedTarget,
  set: (value) => (store.selectedTarget = value),
});
const selectedTags = computed({
  get: () => store.selectedTags,
  set: (value) => (store.selectedTags = value),
});
const selectedDepartment = computed({
  get: () => store.selectedDepartment,
  set: (value) => (store.selectedDepartment = value),
});
const selectedDuration = computed({
  get: () => store.selectedDuration,
  set: (value) => (store.selectedDuration = value),
});
const tagsDisabled = ref(true);

const uniqueTypes = [...new Set(props.data.map((item) => item.type))]
  .sort()
  .map((type) => ({ name: type }));

const uniqueTopics = [
  ...new Set(
    props.data.flatMap((item) => item.specification.map((spec) => spec.topic))
  ),
]
  .sort()
  .map((topic) => ({ name: topic }));

const uniqueTarget = [...new Set(props.data.map((item) => item.target))]
  .sort()
  .map((target) => ({ name: target }));

const uniqueDuration = [...new Set(props.data.map((item) => item.duration))]
  .sort((a,b) => a -b)
  .map((target) => ({ name: target }));

const uniqueTags = computed(() => {
  if (!selectedType.value) return [];
  const tags = [
    ...new Set(
      props.data
        .filter((item) => item.type === selectedType.value.name)
        .flatMap((item) => item.tag)
    ),
  ]
    .sort()
    .map((tag) => ({ name: tag }));
  return tags;
});

const filterByDepartment = (department) => {
  selectedDepartment.value = department;
};

watch(
  [
    selectedType,
    selectedTopic,
    selectedTarget,
    selectedTags,
    selectedDepartment,
    selectedDuration,
  ],
  () => {
    tagsDisabled.value =
      !selectedType.value &&
      !selectedTopic.value &&
      !selectedTarget.value &&
      !selectedTags.value &&
      !selectedDuration.value &&
      !selectedDepartment.value;
    store.setFilters({
      type: selectedType.value,
      topic: selectedTopic.value,
      target: selectedTarget.value,
      tags: selectedTags.value,
      department: selectedDepartment.value,
      duration: selectedDuration.value,
    });
  }
);

const clearFilters = () => {
  selectedType.value = null;
  selectedTopic.value = null;
  selectedTarget.value = null;
  selectedTags.value = null;
  selectedDepartment.value = null;
  selectedDuration.value = null;
  tagsDisabled.value = true;
  store.setFilterActive(true);
};
</script>

<style scoped>
.btn-filter {
  background: transparent !important;
  width: 100%;
  color: #c4e9ff;
  transition: ease-in-out;
  transition-duration: 0.2s;
  text-align: left;
}
.btn-filter:hover {
  color: rgba(254, 196, 30, 1);
  background-color: transparent;
}
.p-inputtext {
  padding: 0.75rem 0.75rem;
  width: 100%;
}
</style>
